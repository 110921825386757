import React, { useState } from 'react';

const DashTimeframe = ({ label, changeTimeframe }) => {
    const [active, setActive] = useState('week');

    const changeState = (timeframe) => {
        setActive(timeframe);
        changeTimeframe(timeframe);
     }

    return (
        <>
            <div className="col-lg-12">
                <div className="portlet-box portlet-fullHeight border0 shadow-sm mb-30">
                    <div className="portlet-header flex-row flex d-flex align-items-center">
                        <div className="flex d-flex flex-column">
                            <h3>{label}</h3>
                        </div>
                        <div className="portlet-tools">
                            <div className="btn-group btn-group-rounded btn-group-sm">
                                <button onClick={() => changeState('week')} type="button" className={"btn btn-primary btn-square" + (active == 'week' ? ' active' : '')}>Week</button>
                                <button onClick={() => changeState('month')} type="button" className={"btn btn-primary btn-square" + (active == 'month' ? ' active' : '')}>Month</button>
                                <button onClick={() => changeState('year')} type="button" className={"btn btn-primary btn-square" + (active == 'year' ? ' active' : '')}>Year</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashTimeframe;
