import React from "react";
import DashChartColumns from "./DashChartColumns";
import ReportDonutChart from "./ReportDonutChart";
import dayjs from "dayjs";

const DashChartAnalytics = ({timeframe, helper}) => {
    const criteria = {
        startDate: dayjs().subtract(1, timeframe).startOf('day').toISOString(),
        endDate: dayjs().endOf('day').toISOString()
    };
    return (
        <>
            <div className="col-lg-12">
                <div className="portlet-box portlet-fullHeight border0 shadow-sm mb-30">
                    <div className="portlet-header flex-row flex d-flex align-items-center" />
                    <div className="portlet-body pt-0">
                        <div className="row align-items-center">
                            <div className='col-lg-3'>
                                <ReportDonutChart
                                    dateRange={criteria}
                                    campaignType={null}
                                    campaignId={null}
                                    helper={helper}
                                />
                            </div>
                            <div className='col-lg-9'>
                                <DashChartColumns
                                    dateRange={criteria}
                                    timeframe={timeframe}
                                    helper={helper}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashChartAnalytics;