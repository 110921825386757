import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import * as dot from "dot-object";
import {RootState, store} from "../../app/store";

const storageName = 'CosmosPrefs';

export type Selector<S> = (state: RootState) => S;

export type prefPayload = {
	key: string, // this should be in dot notation for the whole nested structure, eg. marketing.campaigns.selectors.campaignType
	value: any, // this can be another object, array, etc.
}

export type prefCategories = {
	marketing: any;
	training: any;
	// add additional departments as we go
}

export type crmlsUserPrefs = {
	APIPrefs: prefCategories,
	localPrefs: prefCategories,
	sessionPrefs: prefCategories,
}

// Define the initial state
const initialState: crmlsUserPrefs = {
	APIPrefs: {
		marketing: null,
		training: null,
		...(window.localStorage.getItem("APIPrefs") ? JSON.parse(window.localStorage.getItem("APIPrefs") as string) : {}),
	},
	localPrefs: {
		marketing: null,
		training: null,
	},
	sessionPrefs: {
		marketing: null,
		training: null,
	},
}

export const fetchAPIMemberPrefs = createAsyncThunk(
    'prefs/fetchAPIMemberPrefs',
    async () => {
	// do request to API service here
	return {
		marketing: {},
		training: {},
	};
}
)

export const fetchStoredMemberPrefs = createAsyncThunk("prefs/fetchStoredMemberPrefs", async () => {
	// do request to local storage service here
	let retrievedPrefs = localStorage.getItem(storageName);

	if (retrievedPrefs && retrievedPrefs !== '') {
		return JSON.parse(retrievedPrefs);
	}
}
)

export const fetchSessionMemberPrefs = createAsyncThunk(
    'prefs/fetchSessionMemberPrefs',
    async () => {
	// do request to session service here
	let retrievedPrefs = sessionStorage.getItem(storageName);

	if (retrievedPrefs && retrievedPrefs !== '') {
		return JSON.parse(retrievedPrefs);
	}
}
)

export const prefsSlice = createSlice({
	name: 'prefs',
	initialState,
	reducers: {
		setAPIPref: (state, action: PayloadAction<prefPayload>) => {
			let currentState = {...state.APIPrefs};
			// unset the current path or dot-object pukes
			dot.delete(action.payload.key, currentState);
			dot.str(action.payload.key, action.payload.value, currentState);
			window.localStorage.setItem("APIPrefs", JSON.stringify(currentState));
			// TODO: write back to storage
			state.APIPrefs = currentState;
		},
		setLocalStoragePref: (state, action: PayloadAction<prefPayload>) => {
			let currentState = {...state.localPrefs};
			// unset the current path or dot-object pukes
			dot.delete(action.payload.key, currentState);
			dot.str(action.payload.key, action.payload.value, currentState);
			// write back to storage
			localStorage.setItem(storageName, JSON.stringify(currentState));
			state.localPrefs = currentState;
		},
		setSessionPref: (state, action: PayloadAction<prefPayload>) => {
			let currentState = {...state.sessionPrefs};
			// unset the current path or dot-object pukes
			dot.delete(action.payload.key, currentState);
			dot.str(action.payload.key, action.payload.value, currentState);
			// write back to storage
			sessionStorage.setItem(storageName, JSON.stringify(currentState));
			state.sessionPrefs = currentState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAPIMemberPrefs.fulfilled, (state, action) => {
				state.APIPrefs = action.payload;
			})
			.addCase(fetchStoredMemberPrefs.fulfilled, (state, action) => {
				state.localPrefs = action.payload;
			})
			.addCase(fetchSessionMemberPrefs.fulfilled, (state, action) => {
				state.sessionPrefs = action.payload;
			})
    }
})

export const selectPrefByPath = (path: string): any => {
	let state: RootState = store.getState();

	// loop over the settings starting from the API level settings and work down
	if (dot.pick(path, state.prefs.APIPrefs)) {
		return dot.pick(path, state.prefs.APIPrefs);
	}
	if (dot.pick(path, state.prefs.localPrefs)) {
		return dot.pick(path, state.prefs.localPrefs);
	}
	if (dot.pick(path, state.prefs.sessionPrefs)) {
		return dot.pick(path, state.prefs.sessionPrefs);
	}

	return undefined;
}

export const { setAPIPref, setLocalStoragePref, setSessionPref } = prefsSlice.actions;
const { reducer } = prefsSlice;
export default reducer;
