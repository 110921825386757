import React from "react";

export const IconText: { [key: string]: string } = {
	published: "fas fa-book text-info",
	pending: "fas fa-clock text-warning",
	sending: "fas fa-paper-plane text-success",
	completed: "far fa-check-circle text-success",
	finalized: "far fa-check-circle text-success",
	approved: "fa fa-check text-success",
	cancelled: "far fa-times-circle text-danger",
	rejected: "far fa-times-circle text-danger",
	expired: "fas fa-clock text-danger",
	archived: "fa fa-trash text-muted",
	draft: "fas fa-hammer text-muted",
	deleted: "fa fa-trash text-danger",
	unknown: "fa fa-question text-muted",
};

export const StatusIcon = (props: any) => {
	const status = props.object.status ? props.object.status.toLowerCase() : "unknown";
	const statusIconText = IconText[status] || "fa fa-question text-muted";
	return <i title={props.object.status} className={`px-3 ${statusIconText}`} />;
};

/*
SYSTEM STATUS OPTIONS
=====================
MACE:
---------------------
CAMPAIGN STATUSES
draft
pending
approved
sending
completed
cancelled
archived
rejected
expired
deleted

TRAINING:
---------------------
CLASS STATUSES
draft
published
finalized
cancelled
*/
