import React from "react";
import User from "./Widgets/User";
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <header className="navbar page-header whiteHeader navbar-expand-lg">
            <ul className="nav flex-row mr-auto">
                <li className="nav-item">
                    <a href="#" className="nav-link sidenav-btn h-lg-down">
                        <span className="navbar-toggler-icon" />
                    </a>
                    <a className="nav-link sidenav-btn h-lg-up" href="#page-aside" data-toggle="dropdown"
                       data-target="#page-aside">
                        <span className="navbar-toggler-icon" />
                    </a>
                </li>
            </ul>
            <ul className="nav flex-row order-lg-2 ml-auto nav-icons">
                <li className="nav-item dropdown tools-dropdown h-lg-down">
                    <Link to="/" data-toggle="dropdown" className=" nav-link">
                        <i className="icon-Filter-2" />
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                        <div className="row no-margin">
                            <div className="col-4 p-3 text-center">
                                <Link to="/communications/campaigns" className="d-block tool-item">
                                    <i className="icon-New-Mail text-teal" />
                                    <div className="tool-heading fs12">Campaign</div>
                                </Link>
                            </div>
                            <div className="col-4 p-3 text-center">
                                <Link to="/communications/audiences" className="d-block tool-item">
                                    <i className="icon-People-onCloud text-success" />
                                    <div className="tool-heading fs12">Audience</div>
                                </Link>
                            </div>
                            <div className="col-4 p-3 text-center">
                                <Link to="/" className="d-block tool-item">
                                    <i className="icon-Administrator text-primary" />
                                    <div className="tool-heading fs12">My Profile</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item dropdown user-dropdown align-items-center">
                    <User />
                </li>
                <li className="h-lg-up nav-item">
                    <a href="#" className=" nav-link collapsed" data-toggle="collapse" data-target="#navbarToggler"
                       aria-expanded="false">
                        <i className="icon-Magnifi-Glass2" />
                    </a>
                </li>
            </ul>
            <div className="collapse navbar-collapse search-collapse" id="navbarToggler">
                <form className="form-inline ml-1">
                    <button className="no-padding bg-trans border0" type="button"><i
                        className="icon-Magnifi-Glass2" /></button>
                    <input className="form-control border0" type="search" placeholder="Search..."
                           aria-label="Search" />
                </form>
            </div>
        </header>
    );
}

export default Header;
