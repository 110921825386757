import React from "react";
import Menu from "./Widgets/Menu";
import {Link} from "react-router-dom";
import {getEnvironment} from "../features/userFeature/userSlice";
import {useAppSelector} from "../app/hooks";
import {RootState} from "../app/store";

const LogoBar = ({version}: {version: string}) => {
    const environment = useAppSelector((state: RootState) => getEnvironment(state));
    /*
     * Color code:
     * Dev / Localhost: YELLOW
     * Test / UAT: GREEN
     * Staging: RED
     * Prod: WHITE
     */
    const getLogo = () => {
        let logo = <img alt="Mace" src="/images/CRMLSLogoYellow.png" width="125"/>;

        switch (environment) {
            case "development":
                logo = <img alt="Mace" src="/images/CRMLSLogoYellow.png" width="125"/>;
                break;
            case "testing":
                logo = <img alt="Mace" src="/images/CRMLSLogoGreen.png" width="125"/>;
                break;
            case "staging":
                logo = <img alt="Mace" src="/images/CRMLSLogoRed.png" width="125"/>;
                break;
            case "production":
                logo = <img alt="Mace" src="/images/CRMLSLogoWhite.png" width="125"/>;
                break;
        }

        return logo;
    }

    return (
        <aside id="page-aside" className=" page-aside aside-fixed">
            <div className="sidenav darkNav">
                <Link to="/"
                   className="app-logo d-flex flex flex-row align-items-center overflow-hidden justify-content-center">
                      <span className="logo-text d-inline-flex">
                          {getLogo()}
                      </span>
                </Link>
                <div className="flex">
                    <div id="reactMenu" className="aside-content slim-scroll">
                        <Menu />
                    </div>
                </div>
                <div className="p-3 pl-25">
                    COSMOS Version - <span id="reactVersion">{version}</span>
                </div>
            </div>
        </aside>
    );
}

export default LogoBar;
