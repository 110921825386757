import React from "react";
import ReactDOM from "react-dom";
import Unsubscribe from "./Components/Main/Unsubscribe";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { EventType, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./Auth/authProvider";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import Unauthenticated from "./Components/Unauthenticated";
import App from "./App";
import ThemeLoader from "./Components/Widgets/ThemeLoader";

export const msalInstance = new PublicClientApplication(msalConfig);

// This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: any) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	}
});

function ErrorComponent({ error }: { error: any }) {
	if (error) {
		return (
			<div className="alert alert-danger" role="alert">
				<h4 className="alert-heading">{error.name}</h4>
				<p>{error.message}</p>
			</div>
		);
	} else {
		return <></>;
	}
}

function LoadingComponent() {
	return <ThemeLoader />;
}

ReactDOM.render(
	<BrowserRouter>
		<Switch>
			<Route exact path="/unsubscribe">
				<Unsubscribe />
			</Route>
			<Route path="*">
				<Provider store={store}>
					<MsalProvider instance={msalInstance}>
						<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={ErrorComponent} loadingComponent={LoadingComponent}>
							<App />
						</MsalAuthenticationTemplate>
						<UnauthenticatedTemplate>
							<Unauthenticated />
						</UnauthenticatedTemplate>
					</MsalProvider>
				</Provider>
			</Route>
		</Switch>
	</BrowserRouter>,
	document.getElementById("page-wrapper")
);
