/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { OrderByType, selectorQueryDefinition, SyncfusionOptions } from "../../Reporting/Configuration";
import useQuery from "../../../Hooks/Query";
import QueryHelper from "../../../Utilities/QueryHelper";
import { ColumnChooser, ColumnDirective, ColumnsDirective, EditSettingsModel, GridComponent, Group, Inject, Page, Resize, Search, Sort, Toolbar } from "@syncfusion/ej2-react-grids";
import { swalWithBootstrapButtons } from "../../../Utilities/SweetAlert";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getUserId } from "../../../../features/userFeature/userSlice";
import { AbilityContext } from "../../../../Auth/can";
import ThemeLoader from "../../../Widgets/ThemeLoader";
import "../../../../CSS/grid.css";
import GenericSyncfusionSelectors from "./GenericSyncfusionSelectors";
import GenericActionsMenu from "./GenericActionsMenu";
import isEqual from "lodash/isEqual";
import _, { isArray } from "lodash";
import MemberLookup from "../../../Widgets/MemberLookup";
import { QueryParameterOperation, icons, unassignedCaseDefault } from "../../Compliance/shared/helpers";
import { setAPIPref } from "../../../../features/prefsFeature/prefsSlice";
import { debounce } from "lodash";

const initialState = {
	result: undefined as any,
	count: 0,
};

const initialQueryData = {
	pageId: 0,
	pageSize: 10,
	criteria: [] as any[],
	orderBy: [] as any[],
	fields: [] as any[],
	refresh: undefined,
};

export const findObjectTitle = (options: SyncfusionOptions) => {
	let title;

	options.columns.forEach((col) => {
		if (col.id === "name" || col.isLabel) {
			title = col.id;
		}
	});

	return title;
};

const GenericSyncfusionTable = ({ options }: { options: SyncfusionOptions }) => {
	const ability = useContext(AbilityContext);
	const accountId = useAppSelector(getUserId);
	const [activePage, setActivePage] = useState(0);
	const [pageSize, setPageSize] = useState(options.defaultPageSize);
	const [isLoaded, setIsLoaded] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [error, setError] = useState<string>();
	const [searchString, setSearchString] = useState("");
	const [memberIdString, setMemberIdString] = useState("");
	const [settings] = useState(JSON.parse(window.localStorage.getItem("APIPrefs") as string)?.[options.resourceType]?.[options.resourceName]);
	const [selectorQuery, setSelectorQuery] = useState<selectorQueryDefinition[]>(options.enablePersistence && settings?.selectors ? settings.selectors : []);
	const [sortParams, setSortParams] = useState<OrderByType[]>(options.enablePersistence && settings?.orderBy ? settings?.orderBy : []);
	const [helper, setHelper] = useState<QueryHelper | null>(null);
	const gridControl = useRef<any>("");
	useQuery(setHelper);
	let history = useHistory();
	const [items, setItems] = useState(initialState);
	const [previousQueryData, setPreviousQueryData] = useState<typeof initialQueryData>();
	const [queryData, setQueryData] = useState<typeof initialQueryData>();
	const [refreshData, setRefreshData] = useState(false);
	const formView = options.parent && options.parent.id ? options.parent.formView : options.routePath;
	let orderByPath = [options.resourceType, options.resourceName, "orderBy"].join(".");
	let pageSizePath = [options.resourceType, options.resourceName, "pageSize"].join(".");
	let pageIdPath = [options.resourceType, options.resourceName, "pageId"].join(".");
	const [multiSearchTerm, setMultiSearchTerm] = useState("");

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (helper) {
			buildQuery();
		}
	}, [isLoaded, helper, activePage, pageSize, searchString, sortParams, options, selectorQuery, memberIdString]);

	useEffect(() => {
		if (options.afterFetchCallback && items) {
			options.afterFetchCallback(items);
		}
		if (items?.result?.length || items?.result) {
			//TODO this is a hack fix to keep the page from cashing on table load.  unsure as to why this happens
			setTimeout(() => {
				setIsLoaded(true);
				if (gridControl.current) {
					gridControl.current.hideSpinner();
				}
			}, 1000);
		}
	}, [items]);

	useEffect(() => {
		if (!refreshData && isEqual(queryData, previousQueryData)) {
			return;
		}

		if (helper && queryData) {
			fetchNow();
		}
	}, [queryData]);

	const fetchNow = () => {
		if (helper) {
			if (options.onLoadCallback) {
				options.onLoadCallback(false);
			}
			if (isLoaded && gridControl.current) {
				gridControl.current.showSpinner();
			}

			if (options.resourceCriteria) {
				helper.criteria = [...helper.criteria, ...options.resourceCriteria];
			}
			if (!options.getResource) {
				helper
					.fetchData(options.resourceName)
					.then((result) => {
						if (options.onLoadCallback) {
							options.onLoadCallback(true);
						}
						return result;
					})
					.then((result) => {
						setItems({
							result: result.results,
							count: result.totalResults,
						});
					})
					.then(() => {
						if (isLoaded && gridControl.current) {
							gridControl.current.hideSpinner();
						}
					})
					.catch((error: any) => {
						setError(error.message);
						console.error(`error: ${error}`);
					});
			} else {
				helper
					.get(options.resourceName)
					.then((result) => {
						if (options.onLoadCallback) {
							options.onLoadCallback(true);
						}
						return result;
					})
					.then((result) => {
						setItems({
							result: result.results,
							count: result.totalResults,
						});
					})
					.then(() => {
						if (isLoaded && gridControl.current) {
							gridControl.current.hideSpinner();
						}
					})
					.catch((error: any) => {
						setError(error.message);
						console.error(`error: ${error}`);
					});
			}
			setPreviousQueryData(queryData);
		}
	};
	const buildQuery = () => {
		if (helper) {
			helper.criteria = [];
			switch (options.service) {
				case "training":
					helper.setTraining();
					break;
				case "membership":
					helper.setMembership();
					break;
				case "membershipv3":
					helper.setMembershipV3();
					break;
				case "mace":
					helper.setMace();
					break;
				case "notification":
					helper.setNotification();
					break;
				case "compliance":
					helper.setCompliance();
					break;
				case "memberPortal":
					helper.setMemberPortal();
					break;
			}
			helper.pageSize =
				options.enablePersistence && JSON.parse(window.localStorage.getItem("APIPrefs") as string)?.[options.resourceType]?.[options.resourceName]?.pageSize ? JSON.parse(window.localStorage.getItem("APIPrefs") as string)?.[options.resourceType]?.[options.resourceName]?.pageSize : pageSize;
			helper.pageId =
				options.enablePersistence && JSON.parse(window.localStorage.getItem("APIPrefs") as string)?.[options.resourceType]?.[options.resourceName]?.pageId ? JSON.parse(window.localStorage.getItem("APIPrefs") as string)?.[options.resourceType]?.[options.resourceName]?.pageId - 1 : activePage;
			setActivePage(helper.pageId);

			if (sortParams.length) {
				helper.orderBy = sortParams;
				let svs: OrderByType[] = [];
				sortParams.forEach((sp) => {
					svs.push({
						field: sp.field,
						direction: sp.direction,
					});
				});
				if (options.enablePersistence) {
					dispatch(
						setAPIPref({
							key: orderByPath,
							value: svs,
						})
					);
				}
			} else if (options.defaultSort && options.defaultSort.columns) {
				// convert from syncfusion sort object to q format
				helper.orderBy = options.defaultSort.columns.map((c) => {
					return {
						field: c.field,
						direction: c.direction === "Descending" ? "Desc" : "Asc",
					};
				});
				if (options.enablePersistence) {
					dispatch(
						setAPIPref({
							key: orderByPath,
							value: [],
						})
					);
				}
			}

			if (selectorQuery.length) {
				// IF there's a default query, check if there's a selector intentionally set that overrides the
				// default query-- if not, then include the default query alongside the selectors
				if (options.defaultQuery) {
					let setDefault = true;
					options.defaultQuery?.forEach((dq) => {
						let field = dq.field;
						selectorQuery.forEach((sq) => {
							if (sq.field === field) {
								setDefault = false;
							}
						});
					});
					if (setDefault) {
						helper.criteria = options.defaultQuery;
					}
				}

				helper.criteria = [...helper.criteria, ...selectorQuery];
			} else if (options.defaultQuery) {
				helper.criteria = options.defaultQuery;
			}

			helper.criteria = helper.criteria.filter((criteria) => criteria.field !== options.searchField);
			if (searchString.length) {
				helper.criteria.push({
					field: options.searchField,
					op: 10,
					values: [searchString],
				});
			}
			if (memberIdString?.length) {
				helper.criteria.push({
					field: "memberId",
					op: 0,
					values: [memberIdString],
				});
			}

			setQueryData({
				pageId: helper.pageId,
				pageSize: helper.pageSize,
				criteria: helper.criteria,
				orderBy: helper.orderBy,
				fields: helper.fields,
				refresh: options.refreshData,
			});
		}
	};

	const handleStateChange = (state: any) => {
		if (state.action.requestType === "refresh" && state.action.name === "actionBegin") {
			gridControl.current.hideSpinner();
		}

		if (state.action) {
			if (options.resourceType !== "compliance") {
				setItems(initialState); // try clearing this here
			}
			if (state.action.requestType === "sorting") {
				if ("sorted" in state) {
					let sortOrder: OrderByType[] = [];
					state.sorted.forEach((sort: any) => {
						sortOrder.push({
							field: sort.name,
							direction: sort.direction === "ascending" ? "Asc" : "Desc",
						});
					});
					setActivePage(0);
					setSortParams(sortOrder);
				} else {
					setSortParams([]);
				}
			} else if (state.action.requestType === "searching") {
				setActivePage(0);
				setSearchString(state.action.searchString);
			} else if (state.action.requestType === "paging") {
				dispatch(
					setAPIPref({
						key: pageSizePath,
						value: state.take,
					})
				);

				if (state.take !== pageSize) {
					setPageSize(state.take);
				}
				if (state.action.currentPage - 1 !== activePage) {
					dispatch(
						setAPIPref({
							key: pageIdPath,
							value: state.action.currentPage,
						})
					);
					setActivePage(state.action.currentPage - 1); // we're zero-indexed so subtract
				}
			}
		} else {
			state.skip = 1;
		}
	};

	const pageOptions = {
		pageCount: 4,
		pageSize: pageSize,
		pageSizes: ["5", "10", "20", "50"],
	};

	const deleteObject = (id: string) => {
		swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				text: "You won't be able to revert this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Delete it!",
				cancelButtonText: "Cancel!",
				reverseButtons: true,
			})
			.then((result) => {
				if (result.isConfirmed && helper) {
					helper.deleteResource(options.resourceName, id).then((result) => {
						options.refreshData = !refreshData;
						buildQuery();
						swalWithBootstrapButtons.fire("Deleted!", `${options.singularTitle} deleted.`, "success");
					});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					console.log("cancelled");
				}
			});
	};

	const cloneObject = (object: any) => {
		let newObject = { ...object };
		if (helper) {
			// remove this prop since it comes from syncfusion grid
			delete newObject.column;
			delete newObject.index;
			delete newObject.id;
			delete newObject.createdOn;
			delete newObject.createdBy;
			delete newObject.modifiedOn;
			delete newObject.modifiedBy;

			// remove any model that isn't set
			if (options.deleteOnUpdate?.length) {
				options.deleteOnUpdate.forEach((dOu) => delete newObject[dOu]);
			}

			// get the name of the title object
			let title = findObjectTitle(options);

			if (title) {
				newObject[title] = newObject[title] + " - CLONE";
			}

			newObject.createdBy = accountId;
			newObject.createdOn = dayjs().toISOString();
			newObject.modifiedBy = accountId;
			newObject.modifiedOn = dayjs().toISOString();

			// Not all cloned objects need a draft status - for now, check if this is a campaign, article or training event being cloned, and update the status if so
			// @TODO: This needs to be better thought out - campaigns store 'status' all uppercase 'DRAFT'. Training classes store 'Draft'.
			if (["campaign", "article", "trainingClass"].includes(options.resourceName)) {
				let newStatus = options.resourceName === "campaign" ? "DRAFT" : "Draft";
				newObject.status = newStatus;
			}
			helper
				.createResource(options.resourceName, newObject)
				.then((data) => {
					history.push(`/${formView}/edit/${data.id}`);
				})
				.catch((error: Error) => setError(error.message));
		}
	};

	const nameTemplate = (props: any) => {
		let field = props.column.field; // this will be the actual column
		let tag = props[field] ?? "No Name";
		if (isArray(tag)) {
			tag = tag.join(", ");
		}
		if (formView) {
			let link = `/${formView}/edit/` + props.id;
			return <Link to={link}>{tag}</Link>;
		}

		return tag;
	};

	// this is stupid but syncfusion can't handle more complex templates
	const booleanTemplate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column
		if (value) {
			return (
				<span className={"text-success"}>
					<i className="fas fa-check" />
				</span>
			);
		} else {
			return (
				<span className={"text-danger"}>
					<i className="fas fa-times" />
				</span>
			);
		}
	};
	const alertTemplate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column
		if (props.unseenChanges) {
			return (
				<span
					style={{
						color: "#e06d6d",
						fontSize: "18px",
						marginLeft: "10px",
					}}
				>
					<i className="fas fa-exclamation" />
				</span>
			);
		} else {
			return (
				<span
					style={{
						marginLeft: "10px",
					}}
				>
					-
				</span>
			);
		}
	};
	const alertFlagTemplate = (props: { column: { field: string }; [key: string]: any }) => {
		const value = props[props.column.field];

		if (value?.length) {
			return (
				<>
					{Object.entries(icons).map(
						([keyword, iconClass], index) =>
							value.includes(keyword) && (
								<span
									key={index}
									style={{
										color: "#e06d6d",
										fontSize: "18px",
									}}
								>
									<i className={iconClass} />
								</span>
							)
					)}
				</>
			);
		} else {
			return <span style={{}}>-</span>;
		}
	};

	const imageTemplate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column

		if (value) {
			return <img src={value} alt={props.name ?? ""} className="img-thumbnail" />;
		} else {
			return <i className="fas fa-image" />;
		}
	};

	const selectTemplate = (props: any) => {
		let column = options.columns.find((c) => {
			return c.id === props.column.field && ["staticSelect", "enumSelect", "staticSelectInt"].includes(c.dataType);
		});
		let value = props[props.column.field]; // this will be the actual value of the column
		if (column && column.values) {
			switch (column.dataType) {
				case "enumSelect":
					let enumeration = column.values.find((e: any) => e.value === value);
					if (enumeration) {
						return enumeration.label;
					} else {
						return options.noEnumFoundText ? options.noEnumFoundText + value : "No value found for " + value;
					}
				case "staticSelect":
					return value ?? "Undefined";
				case "staticSelectInt":
					return column.values[parseInt(value)];
				default:
					// catchall
					return column.values[value] ?? column.values[parseInt(value)];
			}
		} else {
			return options.noEnumFoundText ? options.noEnumFoundText + value : "No value found for " + value;
		}
	};

	const customTemplate = (props: any) => {
		let c = options.columns.find((column) => column.id === props.column.field);
		if (c && c.customComponent) {
			return <c.customComponent object={props} />;
		}
	};

	const actionsTemplate = (props: any) => {
		let itemActions: any[] = [];
		if (options.actions && options.actions.length) {
			itemActions = [
				...options.actions.map((action) => {
					return { ...action, callback: () => action.callback(props) };
				}),
			];
		}

		if (options.showClone === true && ability.can("clone", options.resourceType)) {
			itemActions.push({ label: "Clone Item", iconClass: "far fa-copy text-info", callback: () => cloneObject(props) });
		}

		if (options.showDelete === true && ability.can("delete", options.resourceType)) {
			itemActions.push({ label: "Delete Item", iconClass: "far fa-trash-alt text-danger", callback: () => deleteObject(props.id) });
		}

		if (options.showEdit === true) {
			itemActions.push({
				label: "Edit Item",
				iconClass: "far fa-edit",
				callback: () => {
					options.editClick ? options.editClick({ data: props }) : options.rowClick({ data: props });
				},
			});
		}

		if (options.showAssign === true) {
			itemActions.push({
				label: options.assignCopy ? options.assignCopy : props.assignTo === unassignedCaseDefault ? "Assign" : "Reassign",
				iconClass: "far fa-edit",
				callback: (e: any) => {
					options.assignClick({ e, data: props });
				},
			});
		}

		if (options.showClaim === true) {
			if (props.assignTo === unassignedCaseDefault || options.userType === "ADMIN") {
				itemActions.push({
					label: "Claim",
					iconClass: "far fa-edit",
					callback: (e: any) => {
						options.assignClick({ e, data: { ...props, claim: true } });
					},
				});
			}
		}

		return <GenericActionsMenu actions={itemActions} />;
	};

	const dateTemplate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column
		if (value) {
			return dayjs(value).format("MMM D, YYYY hh:mm A");
		}
	};

	const shortDate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column
		if (!value) {
			value = props.createdOn;
		}
		if (value) {
			return dayjs(value).format("MM/DD/YYYY");
		} else return "";
	};

	const textTemplate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column
		return value ? value : "-";
	};

	const untruncatedText = (props: any) => {
		let value = <>{props[props.column.field]}</>; // this will be the actual value of the column
		return value ? <div style={{ maxWidth: "900px", whiteSpace: "normal" }}>{value}</div> : "-";
	};
	const htmlText = (props: any) => {
		let value = <>{props[props.column.field]}</>; // this will be the actual value of the column
		return value ? <div style={{ maxWidth: "900px", whiteSpace: "normal" }} dangerouslySetInnerHTML={{ __html: props[props.column.field] }}></div> : "-";
	};

	const ticklerTemplate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column
		return !value ? "" : <div className="fas fa-exclamation-circle"></div>;
	};

	let keyLookups: string[] = [];
	const arrayTemplate = (props: any) => {
		let value =
			Array.isArray(props[props.column.field]) && props[props.column.field].length ? (
				<>
					<ul>
						{props[props.column.field]?.map((item: any, index: number) => (
							<li key={index}>{keyLookups?.map((keyName: string) => item["mlsRule"]?.[keyName] + " ")}</li>
						))}
					</ul>
				</>
			) : undefined; // this will be the actual value of the column
		return value ? value : "None";
	};
	const arrayCountTemplate = (props: any) => {
		let value = Array.isArray(props[props.column.field]) && props[props.column.field].length ? props[props.column.field].length : undefined; // this will be the actual value of the column
		return value ? value : "None";
	};

	const subResourceTemplate = (props: any) => {
		let column = options.columns.find((c) => c.id === props.column.field);

		// this template is used if we need to drill down into a subresource using dot notation
		// so we'll split the column name on the dots
		let columnParts = props.column.field.split(".");

		// TODO: hardcode this to nesting only one level deep for now
		let value = null;

		if (props.hasOwnProperty(columnParts[0]) && props[columnParts[0]]) {
			value = props[columnParts[0]][columnParts[1]]; // this will be the actual value of the column
		}

		if (value) {
			if (column && column.isLabel) {
				let link = `/${formView}/edit/` + props.id;
				return <Link to={link}>{value}</Link>;
			}
			return value;
		} else {
			return "";
		}
	};

	const buildFilters = () => {
		if (!options.selectors || options.selectors.length === 0) {
			return <></>;
		}

		return <GenericSyncfusionSelectors options={options} setSelectorQuery={setSelectorQuery} showClearBtn={true} />;
	};

	// Table header toolbar actions
	let toolbarActions = [];
	if (options.searchField) {
		toolbarActions.push("Search");
	}
	if (options.showRefresh !== false) {
		toolbarActions.push({ text: "Refresh", tooltipText: "Refresh List", prefixIcon: "e-refresh", id: "RefreshView" });
	}
	toolbarActions.push("ColumnChooser");

	const handleToolbarClick = (args: any) => {
		if (args.item.id === "RefreshView") {
			fetchNow();
		}
	};

	const getCustomToolbarAction = (tool: any, key: number) => {
		return (
			<li key={key} className="nav-item">
				{tool}
			</li>
		);
	};

	let tableColumns = options.columns.filter((col) => col.showInTable !== false);
	tableColumns = _.sortBy(tableColumns, "tableOrder");

	const memberSelected = (memberId: string) => {
		setMemberIdString(memberId);
	};
	const tableCreated = (args: any) => {
		document
			?.getElementsByClassName("e-search")[0]
			?.getElementsByClassName("e-input")[0]
			?.setAttribute("placeholder", options.defaultSearchPlaceholder || "Search");
		//const inputElement = document.querySelector(".e-input") as HTMLInputElement;
		//inputElement.value = "";
		// get input by class name and clear it
	};
	const [searchOnField, setSearchOnField] = useState(options.columns.filter((c) => c.searchable)[0]?.id);

	const handleSearch = (term: string) => {
		debounceSearch.cancel();
		if (isLoaded && gridControl.current) {
			gridControl.current.showSpinner();
		}
		if (helper) {
			const checkForOverride = options.columns.find((c) => c.id === searchOnField)?.searchOp;
			helper.pageId = 0;
			helper.criteria = [
				{
					field: searchOnField,
					op: checkForOverride !== undefined ? checkForOverride : QueryParameterOperation.Contains,
					values: [term],
				},
			];
			helper
				.fetchData(options.resourceName)
				.then((result) => {
					if (options.onLoadCallback) {
						options.onLoadCallback(true);
					}
					return result;
				})
				.then((result) => {
					setItems({
						result: result.results,
						count: result.totalResults,
					});
				})
				.then(() => {
					if (isLoaded && gridControl.current) {
						gridControl.current.hideSpinner();
					}
				})
				.catch((error: any) => {
					setError(error.message);
					console.error(`error: ${error}`);
				});
		}
	};
	const debounceSearch = debounce(handleSearch, 500);
	const debouncedHandleSearch = useCallback(debounceSearch, [helper, searchOnField]);
	if (error?.length) {
		return <div className="alert alert-danger">{error}</div>;
	} else if (isLoaded) {
		return (
			<>
				<div className="row generic_table">
					<div className={`${options.hideShadow ? "shadow-sm" : ""} portlet-box portlet-fullHeight border0 mb-3`}>
						<div className="portlet-header flex-row flex d-flex align-items-center b-b">
							<div className="flex d-flex flex-column">
								<h3 dangerouslySetInnerHTML={{ __html: options.title }}></h3>
							</div>
							<div className="portlet-tools">
								<ul className="nav">
									{ability.can("manage", options.resourceType) && options.showNew !== false && formView && (
										<li className="nav-item">
											<Link to={`/${formView}/new`} className="btn btn-sm btn-icon btn-success btn-square">
												<i className="fa fa-plus" /> New
											</Link>
										</li>
									)}
									{/* Additional toolbar options */}
									{options.customToolbar && options.customToolbar.length && options.customToolbar.map((tool, key) => getCustomToolbarAction(tool, key))}
								</ul>
							</div>
						</div>
						<div className="portlet-body no-padding">
							{buildFilters()}
							<div className={`table-responsive col-lg-12  pt-20 pb-20 no-border-lg-down `}>
								{options.columns.filter((col) => col.searchable)?.length > 0 && (
									<div className="gst-table-search-inputs">
										<input
											type="text"
											className="e-input"
											onChange={(e) => {
												debouncedHandleSearch(e.target.value);
												setMultiSearchTerm(e.target.value);
											}}
											value={multiSearchTerm}
											placeholder={`Search by ${options.columns.find((col) => col.id === searchOnField)?.label}`}
										/>
										<select
											className="gst-select-input"
											onChange={(e) => {
												setSearchOnField(e.target.value);
												setMultiSearchTerm("");
											}}
										>
											{options.columns.map((col) => {
												if (col.searchable) {
													return (
														<option key={col.id} value={col.id}>
															{col.label}
														</option>
													);
												}
											})}
										</select>
									</div>
								)}
								{options.showMemberSearch && (
									<div className={`table-member-lookup`}>
										<MemberLookup
											getFullObject={true}
											useTaEndpoint={true}
											placeholder={"Search for member name"}
											memberSelected={() => {}}
											selectedMemberObj={(selected: any) => {
												setMemberIdString(selected?.contactId);
											}}
										/>
									</div>
								)}
								<GridComponent
									className="table-striped"
									dataSource={items}
									created={tableCreated}
									allowPaging={true}
									allowSorting={options.allowSorting === false ? false : true}
									pageSettings={options.enablePersistence ? { pageSize: pageSize ? pageSize : 10, pageSizes: ["5", "10", "20", "50"] } : pageOptions}
									showColumnChooser={true}
									// enablePersistence={options.enablePersistence === true ? true : false}
									enablePersistence={false}
									toolbar={toolbarActions}
									toolbarClick={handleToolbarClick}
									dataStateChange={handleStateChange}
									ref={gridControl}
									resizeSettings={options.resizeSettings ? { mode: "Normal" } : { mode: "Auto" }}
									dataBound={(state) => {
										if (document.querySelectorAll("input[type=search]")[1] && firstLoad) {
											setFirstLoad(false);
											setSearchString((document.querySelectorAll("input[type=search]")[1] as any).value);
										}
										if (!options.manualSizeColumns) {
											gridControl.current.autoFitColumns([]);
										}
									}}
									rowSelected={
										options.rowClick
											? (e) => {
													if (e.isInteracted) {
														options.rowClick(e);
													}
											  }
											: () => {}
									}
									rowDeselected={
										options.rowClick
											? (e) => {
													if (e.isInteracted) {
														options.rowClick(e);
													}
											  }
											: () => {}
									}
								>
									<ColumnsDirective>
										{tableColumns.map((c) => {
											let columnProperties: { [key: string]: any } = {
												key: c.id,
												field: c.id,
												width: c.width ?? undefined,
												headerText: c.label,
												clipMode: "EllipsisWithTooltip",
												hideAtMedia: c.hideAtMedia ? `(min-width: ${c.hideAtMedia}px)` : undefined,
												textAlign: c.textAlign ?? undefined,
												allowSorting: c.allowSorting ?? undefined,
												arrayKeyNames: c.arrayKeyNames ? c.arrayKeyNames : [],
											};

											switch (c.dataType) {
												case "staticSelect":
												case "staticSelectInt":
												case "enumSelect":
													//columnProperties.headerText = '';
													columnProperties.template = selectTemplate;
													break;
												case "image":
													columnProperties.template = imageTemplate;
													columnProperties.textAlign = "center";
													break;
												case "subResource":
													columnProperties.template = subResourceTemplate;
													break;
												case "array":
													keyLookups = columnProperties.arrayKeyNames;
													columnProperties.maxWidth = c.maxWidth;
													columnProperties.minWidth = c.width;
													columnProperties.template = arrayTemplate;
													break;
												case "arrayCount":
													columnProperties.maxWidth = c.maxWidth;
													columnProperties.minWidth = c.width;
													columnProperties.template = arrayCountTemplate;
													break;
												case "date":
													columnProperties.template = dateTemplate;
													break;
												case "shortDate":
													columnProperties.template = shortDate;
													columnProperties.maxWidth = c.maxWidth;
													columnProperties.field = c.id;
													break;
												case "currency":
													// add any specific updates for currency formatting, etc
													break;
												case "boolean":
													columnProperties.template = booleanTemplate;
													break;
												case "alert":
													columnProperties.template = alertTemplate;
													break;
												case "alertFlags":
													columnProperties.template = alertFlagTemplate;
													break;
												case "custom":
													columnProperties.template = customTemplate;
													break;
												case "text":
													columnProperties.template = textTemplate;
													columnProperties.maxWidth = c.maxWidth;
													columnProperties.minWidth = c.width;
													break;
												case "untruncatedText":
													columnProperties.template = untruncatedText;
													break;
												case "html":
													columnProperties.template = htmlText;
													break;
												case "tickler":
													columnProperties.template = ticklerTemplate;
													columnProperties.maxWidth = c.maxWidth;
													columnProperties.minWidth = c.width;
													break;
												default:
													columnProperties.template = nameTemplate;
													columnProperties.maxWidth = 290;
													break;
											}

											return (
												<ColumnDirective
													key={columnProperties.key}
													field={columnProperties.field}
													template={columnProperties.template}
													headerText={columnProperties.headerText}
													clipMode={columnProperties.clipMode}
													hideAtMedia={columnProperties.hideAtMedia}
													textAlign={columnProperties.textAlign}
													type={columnProperties.type ?? undefined}
													format={columnProperties.format ?? undefined}
													width={columnProperties.width ? columnProperties.width : undefined}
													maxWidth={columnProperties.width ? columnProperties.width : undefined}
													minWidth={columnProperties.width ? columnProperties.width : undefined}
													allowSorting={(options.allowSorting && columnProperties.allowSorting) ?? true}
												/>
											);
										})}

										{(ability.can("manage", options.resourceType) || options.displayActionColumnOverride) && (options.showDelete || options.showClone || options.showEdit || options.showAssign || options.showClaim || (options.actions && options.actions.length)) && (
											<ColumnDirective width="40" maxWidth="25" field={"actions"} headerText="Actions" template={actionsTemplate} textAlign={"Right"} allowSorting={false} />
										)}
									</ColumnsDirective>
									<Inject services={[Page, Sort, Group, Search, Toolbar, Resize, ColumnChooser]} />
								</GridComponent>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return <ThemeLoader />;
	}
};

export default GenericSyncfusionTable;
