import React, { useState } from 'react';
import DashRollupStats from "../../Widgets/DashRollupStats";
import DashChartAnalytics from "../../Widgets/DashChartAnalytics";
import DashTimeframe from "../../Widgets/DashTimeframe";
import dayjs from "dayjs";
import useQuery from "../../Hooks/Query";
import {useAppSelector} from "../../../app/hooks";
import {getUserId} from "../../../features/userFeature/userSlice";
import {Link} from "react-router-dom";
import CampaignWrapper from "./Campaigns/CampaignWrapper";

const CommunicationsDashboard = () => {
    const userId = useAppSelector(getUserId);
    const [timeframe, setTimeframe] = useState('week');
    const changeTimeframe = (tf) => {
        setTimeframe(tf);
    }
    const [helper, setHelper] = useState(false);
    useQuery(setHelper);

    return (
        <div className="container-fluid">
                <div className="row">
                    <DashTimeframe label={"Campaign Activity"} changeTimeframe={changeTimeframe} />
                </div>
                <div className="row">
                    <DashRollupStats
                        dateRange={
                            {
                                startDate: dayjs().subtract(1, timeframe).startOf('day'),
                                endDate: dayjs().endOf('day'),
                            }
                        }
                        campaignId={null}
                        helper={helper}
                        completed={false}
                        expected_count={false}
                    />
                </div>
                <div className="row">
                    <DashChartAnalytics
                        timeframe={timeframe}
                        helper={helper}
                    />
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <CampaignWrapper title={'My Campaigns'} query={[
                            {
                                field: 'assignedToId',
                                op: 0,
                                values: [
                                    userId
                                ]
                            },
                            {
                                field: 'status',
                                op: 0,
                                values: [
                                    'DRAFT', 'PENDING', 'APPROVED', 'SENDING','COMPLETED'
                                ]
                            }
                        ]} pageSize={5} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <CampaignWrapper title={'Pending Campaigns'} query={[
                            {
                                field: 'status',
                                op: 0,
                                values: [
                                    'PENDING'
                                ]
                            }
                        ]} pageSize={5} />
                    </div>
                    <div className="col-lg-12">
                        <CampaignWrapper title={'Draft Campaigns'} query={[
                            {
                                field: 'status',
                                op: 0,
                                values: [
                                    'DRAFT'
                                ]
                            }
                        ]} pageSize={5} />
                    </div>
                    <div className="col-lg-12">
                        <CampaignWrapper title={'Sending Campaigns'} query={[
                            {
                                field: 'status',
                                op: 0,
                                values: [
                                    'SENDING'
                                ]
                            }
                        ]} pageSize={5} />
                    </div>
                    <div className="col-lg-12">
                        <CampaignWrapper title={'Approved Campaigns'} query={[
                            {
                                field: 'status',
                                op: 0,
                                values: [
                                    'APPROVED'
                                ]
                            }
                        ]} pageSize={5} />
                    </div>
                    <div className="col-lg-12">
                        <CampaignWrapper title={'Completed Campaigns'} query={[
                            {
                                field: 'status',
                                op: 0,
                                values: [
                                    'COMPLETED'
                                ]
                            }
                        ]} pageSize={5} />
                    </div>
                </div>
            </div>
    );
}

export default CommunicationsDashboard;
