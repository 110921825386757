import React from 'react';
import GenericSyncfusionTable from "../../SubComponents/GenericSyncfusion/GenericSyncfusionTable";
import * as Yup from "yup";
import {SyncfusionOptions} from "../../Reporting/Configuration";
import {StatusIcon} from "../../../Widgets/StatusIcon";
import {store} from "../../../../app/store";

export const campaignStatuses = ['UNDEFINED','DRAFT','PENDING','APPROVED','SENDING','COMPLETED','CANCELLED','ARCHIVED','REJECTED','EXPIRED','DELETED'];

const GetUserDept = () => {
    const state = store.getState();
    return state.user.department.department;
}

export const CampaignResource: SyncfusionOptions = {
    resourceType: 'marketing',
    service: 'mace',
    defaultPageSize: 50,
    searchField: 'name',
    showDelete: true,
    showClone: true,
    resourceName: 'campaign',
    title: 'Campaigns',
    singularTitle: 'Campaign',
    defaultSort: {
        columns: [
            {
                field: 'executionDate',
                direction: 'Descending'
            }
        ]
    },
    defaultQuery: [ // Hide deleted items in default view
        {
            field: 'status',
            op: 6,
            values: ['DELETED']
        }
    ],
    routePath: 'communications/campaigns',

    selectors: [
        {
            id: "audienceId",
            label: "Audience",
            cssClass: "col-md-2 ml-2",
            service: "mace",
        },
        {
            id: 'assignedToId',
            label: 'Assigned To',
            cssClass: 'col-md-2 ml-2',
            service: 'mace',
            selectField: 'displayName',
        },
        {
            id: 'campaignType',
            label: 'Campaign Type',
            cssClass: 'col-md-2 ml-2',
            service: 'mace',
            valueKey: 'name',
            dynamicDefault: () => {
                let dept = GetUserDept();

                switch (dept) {
                    case 'Marketing':
                        return 'MARKETING';
                }
                return undefined;
            }
        },
        {
            id: 'status',
            label: 'Status',
            cssClass: 'col-md-2 ml-2',
            service: 'mace',
        },
    ],

    columns: [
        {
            id: 'assignedToId',
            label: 'Assigned To',
            dataType: 'select',
            selectResource: 'user',
            showInTable: false,
            validation: Yup.string().required('This field is required'),
        },
        {
            id: 'name',
            label: 'Campaign',
            dataType: 'none',
            validation: Yup.string().required('This field is required'),
        },
        {
            id: 'campaignType',
            label: 'Type',
            dataType: 'select',
            selectResource: 'campaignType',
            validation: Yup.string().required('This field is required'),
            hideAtMedia: '1200',
        },
        {
            id: 'executionDate',
            label: 'Execution Date',
            dataType: 'date',
            validation: Yup.string().required('This field is required'),
            hideAtMedia: '1200'
        },
        {
            id: 'sent',
            label: 'Sent',
            dataType: 'none',
            validation: Yup.string().required('This field is required'),
            textAlign: 'Right',
            hideAtMedia: '767'
        },
        {
            id: 'sandbox',
            label: 'Sandbox',
            dataType: 'boolean',
            validation: Yup.string().required('This field is required'),
            textAlign: 'Center',
            hideAtMedia: '767'
        },
        {
            id: 'status',
            label: 'Status',
            dataType: 'custom',
            showOnForm: false,
            customComponent: StatusIcon,
            hideAtMedia: '767'
        },
        {
            id: 'status',
            label: '',
            dataType: 'enumSelect',
            showInTable: false,
            showOnForm: false,
            values: campaignStatuses.map((s) => {
                return {
                    value: s,
                    label: s,
                }
            }),
        },
        {
            id: "audienceId",
            label: "Audience",
            dataType: "select",
            serviceName: "mace",
            selectResource: "Audience",
            showInTable: false,
        },
    ],
}

function Campaigns() {
    return (
        <div className="container-fluid">
            <GenericSyncfusionTable options={CampaignResource} />
        </div>
    );
}

export default Campaigns;
