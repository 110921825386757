import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import Routes, {ROUTESINDIVIDUAL} from "../Utilities/Routes";
import CommunicationsDashboard from "../Main/Communications/CommunicationsDashboard";
import "../../CSS/breadcrumbs.css";


const urlBreadcrumbMap = [
    {
        match: /\/support\/work-items\/.+&referrer=\/support\/my-tickets/,
        route: ROUTESINDIVIDUAL.EDITTICKET_VIEWS
    },
    {
        match: /\/support\/work-items\/.+&referrer=\/support\/tickets/,
        route: ROUTESINDIVIDUAL.EDITTICKET_ALLTICKETS
    },
    {
        match: /\/support\/work-items/,
        route: ROUTESINDIVIDUAL.EDITTICKET_ALLTICKETS
    },
]

const Breadcrumbs = () => {
    let location = useLocation();

    const [currentPath, setCurrentPath] = useState(location.pathname);
    const [currentRouteObject, setCurrentRouteObject] = useState({
        path: "/communications",
        name: "Dashboard",
        Component: CommunicationsDashboard,
        icon: "icon-Gaugage",
        showWelcome: true,
        crumbs: [
            {name: 'Home', path: '/'},
            {name: 'Communications', path: '/communications'},
        ],
    });

    useEffect(function() {
        setCurrentRouteObject(() => {
            let cp = currentRouteObject;
            let newRouteFound = false;
            Routes.forEach(element => {
                if (currentPath.includes(element.rootpath)) {
                    element.paths.forEach(subpath => {
                        if (currentPath.includes(subpath.path)) {
                            cp = subpath;
                            newRouteFound = true;
                        }
                    })
                }
            });

            if (!newRouteFound) {
                const url = `${location.pathname}${location.search}`;
                const routeSerch = urlBreadcrumbMap.find((item) => item.match.test(url))
                if (routeSerch) {
                    cp = routeSerch.route;
                }
            }

            return cp;
        });
    }, [ currentPath, currentRouteObject ] );

    useEffect(function() {
        setCurrentPath(location.pathname)
    }, [location.pathname])

    const getSlug = () =>  {
        if (currentRouteObject.showWelcome) {
            return (
                <div className="list-body">
                    <div className="list-title fs-2x">
                        {/*<h3>Welcome <strong>{props.name}</strong></h3>*/}
                        <span className="full-text"><h3>Marketing and Communication Email (MACE)</h3></span>
                        <span className="short-text"><h3>MACE</h3></span>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="list-body">
                    <div className="list-title fs-2x">
                        <h3><strong>{currentRouteObject.name}</strong></h3>
                    </div>
                </div>
            );
        }
    }

    return (
        <>
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-7">
                    <div className="list">
                        <div className="list-item pl-0">
                            <div className="list-thumb ml-0 mr-3 pr-3  b-r text-muted">
                                <i className={currentRouteObject.icon} />
                            </div>
                            {getSlug()}
                        </div>
                    </div>
                </div>
                <div className="col-md-5 d-flex justify-content-end h-md-down">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb no-padding bg-trans mb-0">
                            <li className="breadcrumb-item"><NavLink to="/"><i className="icon-Home mr-2 fs14" /></NavLink></li>
                            {currentRouteObject.crumbs.map(({ name, path }, key) =>
                                key + 1 === currentRouteObject.crumbs.length ? (
                                        <li key={key} className="breadcrumb-item active"><NavLink key={key} to={path}>{name}</NavLink></li>
                                ) : (
                                    <li key={key} className="breadcrumb-item active"><NavLink key={key} to={path}>{name}</NavLink></li>
                                )
                            )}
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        </>
    );
};

export default Breadcrumbs;
