import React from "react";

const Footer = () => {
    return (
        <footer className="content-footer bg-light b-t">
            <div className="d-flex flex align-items-center pl-15 pr-15">
                <div className="d-flex flex p-3 mr-auto justify-content-end">
                    <div className="text-muted">&copy; Copyright {new Date().getFullYear()} CRMLS</div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
