import React, { useEffect, useRef, useState, useCallback } from "react";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import "@syncfusion/ej2-base/styles/bootstrap.css";
import "@syncfusion/ej2-react-inputs/styles/bootstrap.css";
import "@syncfusion/ej2-react-dropdowns/styles/bootstrap.css";
import "@syncfusion/ej2-popups/styles/bootstrap.css";
import "@syncfusion/ej2-inputs/styles/bootstrap.css";
import { Query } from "@syncfusion/ej2-data";
import useQuery from "../Hooks/Query";
import { debounce } from "lodash";
import axios from "axios";
import { useAppSelector } from "../../app/hooks";
import { getEnvironment } from "../../features/userFeature/userSlice";

const MemberLookup = ({ memberSelected, selectedMemberObj = (member) => {}, getFullObject = false, placeholder = "Find a contact (optional)", defaultValue = "", autoFocus = false, useTaEndpoint = false }) => {
	const [helper, setHelper] = useState(false);
	useQuery(setHelper);
	let listObj = useRef();
	const [isSearching, setIsSearching] = useState(true);
	const [cancelToken, setCancelToken] = useState(undefined);
	const environment = useAppSelector(getEnvironment);

	const optionSelected = (event) => {
		let memberData = event.itemData;

		if (memberData?.id || memberData?.result) {
			// @Note - memberData.id is a json stringified object - we need to parse it to get the member id/data
			if (!getFullObject) {
				memberSelected(JSON.parse(memberData.id).contactId);
			} else {
				if (memberData.result) {
					selectedMemberObj(memberData.result);
				} else {
					selectedMemberObj(JSON.parse(memberData.id));
				}
			}
		} else {
			// Set the callback values blank when clearing the dropdown input or when there is not member data
			memberSelected("");
			if (selectedMemberObj !== undefined) {
				selectedMemberObj("");
			}
		}
	};

	const searchStringChanged = (args) => {
		if (!args.text.length) {
			memberSelected("");
		}
		if (args.text.length < 3) {
			return;
		}
		listObj.current.showSpinner();

		let query = new Query();
		//frame the query based on search string with filter type.
		query = args.text !== "" ? query.where("name", "contains", args.text, true) : query;

		if (args.text.length > 0 && helper) {
			listObj.current.showSpinner();
			helper.criteria = [
				{
					field: "searchIndex",
					op: 10, // contains
					values: [args.text],
				},
			];
			setIsSearching(true);
			helper
				.fetchAudienceMembers()
				.then(
					(result) => {
						args.updateData(
							result.results.map((result) => {
								return {
									id: JSON.stringify(result),
									label: result.firstName + " " + result.lastName + " - " + result.id + " - " + result.emailAddress,
								};
							}, query)
						);
						listObj.current.showClearButton = true;
					},
					(error) => {
						console.log(error);
						args.updateData([], query);
					}
				)
				.then((r) => {
					listObj.current.hideSpinner();
					listObj.current.showClearButton = true;
					setIsSearching(false);
				});
		} else {
			listObj.current.hideSpinner();
		}
	};

	const searchStringChangedTa = (args) => {
		if (!args.text.length) {
			memberSelected("");
		}
		if (args.text.length < 3) {
			return;
		}
		if (typeof cancelToken != typeof undefined) {
			cancelToken.cancel("Operation canceled due to new request.");
		}
		const newToken = axios.CancelToken.source();
		setCancelToken(newToken);

		listObj.current.showSpinner();

		let query = new Query();
		//frame the query based on search string with filter type.
		query = args.text !== "" ? query.where("name", "contains", args.text, true) : query;
		if (args.text.length > 0 && helper) {
			helper.criteria = [
				{
					field: "searchIndex",
					op: 10, // contains
					values: [args.text],
				},
			];
			setIsSearching(true);
			args.updateData([], query);
			listObj.current.showSpinner();
			axios
				.get(`https://membership${environment === "staging" || environment === "production" ? "api-prod" : "-dev"}.azurewebsites.net/api/app/Contacts/ta/nonkeyword/${helper.criteria[0].values[0]}?activeOnly=false`, {
					...helper.membership_config,
					cancelToken: newToken.token,
				})
				.then(
					(result) => {
						args.updateData(
							result.data.results.map((result) => {
								let label = result.firstName + " " + result.lastName + " - " + result.loginId;
								if (result.memberStateLicense) {
									label = label + " - " + result.memberStateLicense;
								}
								return {
									result: result,
									label: label,
								};
							}, query)
						);
						listObj.current.showClearButton = true;
						return result;
					},
					(error) => {
						args.updateData([], query);
					}
				)
				.then((r) => {
					listObj.current.hideSpinner();
					listObj.current.showClearButton = true;
					setIsSearching(false);
					if (!r?.data.results?.length) {
						args.updateData([], query);
					}
				});
		} else {
			listObj.current.hideSpinner();
		}
	};
	const debouncedHandleSearch = debounce(searchStringChangedTa, 500);
	return (
		<span className="syncfusion-search">
			<AutoCompleteComponent
				id="member_search"
				fields={{
					value: "label",
				}}
				filtering={!useTaEndpoint ? searchStringChanged : debouncedHandleSearch}
				minLength={2} // todo research why this didn't work
				popupHeight="250px"
				placeholder={placeholder}
				ref={listObj}
				change={optionSelected}
				noRecordsTemplate={isSearching ? "Searching..." : "No results"}
				value={defaultValue ? defaultValue : undefined}
			/>
		</span>
	);
};

export default MemberLookup;
