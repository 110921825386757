import { useEffect, useRef } from "react";
import QueryHelper from "../Utilities/QueryHelper";
import { useAppSelector } from "../../app/hooks";
import { getEnvironment, getTokens } from "../../features/userFeature/userSlice";

function useQuery(callback) {
	const tokens = useAppSelector(getTokens);
	const environment = useAppSelector(getEnvironment);

	// callback will be a setQueryHelper function
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		if (environment && tokens.mace?.length && tokens.membership?.length && tokens.notification?.length && tokens.workflow?.length) {
			savedCallback.current(new QueryHelper({environment, tokens}));
		}
	}, [tokens, environment]);
}

export default useQuery;
