import React, { useEffect, useState } from "react";
import Routes from "../Utilities/Routes";
import { NavLink, useLocation } from "react-router-dom";
import QueryHelper from "../Utilities/QueryHelper";
import useQuery from "../Hooks/Query";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getUserType, setAccessRoles } from "../../features/userFeature/userSlice";
import ThemeLoader from "./ThemeLoader";

function Menu() {
	let location = useLocation();
	const [activeMenu, setActiveMenu] = useState(location.pathname);
	const [helper, setHelper] = useState<QueryHelper | false>(false);
	const [rolesCalled, setRolesCalled] = useState(false);
	const [routes, setRoutes] = useState<any>(Routes);
	useQuery(setHelper);
	const dispatch = useAppDispatch();
	const userType = useAppSelector(getUserType);

	useEffect(() => {
		setActiveMenu(location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		if (helper && !rolesCalled) {
			setRolesCalled(true);
			helper.setWorkflow();
			helper.fetchUserRoles().then((responseAccessRoles) => {
				dispatch(setAccessRoles(responseAccessRoles));
				const newRoutes = [...routes];
				let routeUpdated = false;
				newRoutes.forEach((route: any) => {
					if (route.adminPaths) {
						route.adminPaths.forEach((adminPath: any) => {
							if ((responseAccessRoles as string[]).includes(adminPath.accessRole)) {
								route.paths = [...route.paths, adminPath];
								routeUpdated = true;
							}
						});
					}
				});

				if (routeUpdated) {
					setRoutes(newRoutes);
				}
			});
		}
	}, [helper]);

	const isActiveSection = (item: any) => {
		return activeMenu.includes(item.rootpath) as any;
	};

	return (
		<>
			<ul className="metisMenu" id="metisMenu">
				{routes.map((item: any, key: number) => {
					return (
						<li key={key} className={`${isActiveSection(item) ? "active" : ""}`}>
							<i className={item.icon + " nav-thumbnail"} />
							<NavLink className={`${item.paths.length > 0 ? "has-arrow" : ""}`} to={item.rootpath}>
								<span className="nav-text">{item.name}</span>
							</NavLink>
							<ul aria-expanded={`${isActiveSection(item) ? "true" : "false"}` as any} className={`${isActiveSection(item) ? "collapse in" : "collapse"}`}>
								{item.paths.map((path: any, path_key: number) => {
									if (!path.childPath) {
										if (path.adminOnly && userType !== "ADMIN") return null;
										return (
											<li key={path_key + path.path} className={`${path.path === activeMenu || ((activeMenu.includes(path.path) || path?.subPaths?.includes(activeMenu)) && path.path !== item.rootpath) ? "active" : ""}`}>
												<i className={path.icon + " nav-thumbnail"} />
												<NavLink to={path.path}>{path.name}</NavLink>
											</li>
										);
									}
								})}
								{item.adminPaths?.length > 0 && !rolesCalled && (
									<li>
										<ThemeLoader height={30} width={30} />
									</li>
								)}
							</ul>
						</li>
					);
				})}
			</ul>
		</>
	);
}

export default Menu;
