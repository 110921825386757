import {AbilityBuilder} from "@casl/ability";

type accessLevel = 'admin' | 'marketing' | 'dev' | 'training' | 'ops' | 'compliance' | 'engineering' | 'default'; // etc

export const getCaslAbilities = (ability: any, dept: string | undefined): void => {
    const { can, rules } = new AbilityBuilder(ability);
    // coming into here we should have `ability` defined as the default ability set-- read all, manage none
    console.log(`Looking for abilities for ${dept}`);

    let access: string | undefined = 'default';

    // get the department access level
    if (dept) {
        access = dept.toLowerCase();
        console.log("Found access level " + access);
    }

    can('manage', 'workflow');

    switch (access) {
        case 'admin':
            console.log("setting admin");
            can('manage', 'all');
            break;
        case 'marketing':
            console.log("setting marketing");
            can('manage', 'communications');
            can('manage', 'marketing');
            break;
        case 'training':
            console.log("setting training");
            can('manage', 'training');

            // @note - this is temporary workaround - give training users marketing admin privs
            can('manage', 'communications');
            can('manage', 'marketing');
            break;
        case 'engineering':
            console.log("setting engineering");
            can('manage', 'all');
            break;
        case 'finance':
            console.log("setting finance");
            can('manage', 'finance');
            break;
        case 'operations':
            console.log("setting operations");
            can('manage', 'operations');
            break;
        case 'dev':
        case 'compliance':
            console.log('setting compliance');
            can('manage', 'compliance');
            break;
        default:
    }

    ability.update(rules);
}
