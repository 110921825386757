import React, {useEffect, useState, useRef} from "react";
import {
    ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Export,
    StackingColumnSeries, DateTime, Legend, Tooltip, DataLabel, Category, Zoom
} from '@syncfusion/ej2-react-charts';
import dayjs from "dayjs";
import { getPalette, getRange } from "../Utilities"
import ThemeLoader from "./ThemeLoader";

const DashChartColumns = ({dateRange, timeframe, helper}) => {
    const chartInstance = useRef();
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [interval, setInterval] = useState('Days');
    const [dateFormat, setDateFormat] = useState();
    
    const parseResults = (results) => {
        let events_we_track = ['delivered', 'open', 'bounce', 'unsubscribe', 'click', 'deferred', 'processed', 'blocked', 'dropped', 'priorbounced', 'priorunsubscribed', 'spamreport', 'sent'];
        // jimmy's not returning data for all dates in range so let's figure that out
        let d_data = getRange(dayjs().subtract(1, timeframe).toISOString(), dayjs().endOf('day').toISOString(), timeframe, events_we_track);
        let data = results.webhookEventStats;

        data.forEach((point) => {
            let new_date;
            if (timeframe === 'year') {
                new_date = new Date(point.year, (point.month - 1), 1);
            } else {
                new_date = new Date(point.year, (point.month - 1), point.day);
            }
            let c = point.count;
            // check if we already have a data item with this date
            let i = d_data.findIndex(element => element.date.getTime() == new_date.getTime());

            if (i > -1) {
                d_data[i][point.event] = c;
            } else if (events_we_track.includes(point.event)) {
                if (['delivered', 'processed', 'dropped'].includes(point.event)) {
                    // use the unique context events
                    c = point.uniqueContextEvents;
                } else if (['click','open'].includes(point.event)) {
                    // if this is opens and clicks, use the unique members count and not the total
                    c = point.uniqueMembers;
                }
                
                let shape = {
                    date: new_date,
                    delivered: 0,
                    open: 0,
                    bounce: 0,
                    unsubscribe: 0,
                    click: 0,
                    sent: 0,
                    spamreport: 0,
                    priorunsubscribed: 0,
                    priorbounced: 0,
                    dropped: 0,
                    blocked: 0,
                    processed: 0,
                    deferred: 0
                }
                // do this nonsense because we need to make sure all the zeros exist per date
                shape[point.event] = c;
                d_data.push(shape);
            }
        });
        
        return d_data;
    }

    useEffect(() => {
        setIsLoaded(false);
        setError(null);
        console.log("fetching column chart")
        let bucket = 'groupByDay';
        let criteria = {...dateRange};

        switch (timeframe) {
            case 'week':
                setInterval('Days');
                setDateFormat('MMM d');
                break;
            case 'month':
                setInterval('Days');
                setDateFormat('M/d');
                break;
            case 'year':
                setInterval('Months');
                setDateFormat('MMMM');
                bucket = 'groupByMonth';
                break;
        }

        criteria[bucket] = true;

        // set the vars for the query helper
        if (helper) {
            helper.fetchReportData(criteria)
                .then(
                    (result) => {
                        if (result.isSuccessful) {
                            setError(null)
                            setIsLoaded(true);
                            setData(parseResults(result.results[0]));
                        } else {
                            setError(result.message)
                        }
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error.message);
                    }
                )
        }
    }, [timeframe, helper])

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return (
            <ThemeLoader />
        );
    } else {
        return (
            <>
                <button onClick={() => chartInstance.current.exportModule.export('PDF', 'report')} className={"float-right btn btn-square btn-info btn-sm"}>
                    <i className="far fa-file-pdf" /> PDF</button><br />
                <ChartComponent
                    id='charts'
                    ref={chartInstance}
                    style={{ textAlign: "center" }}
                    primaryXAxis={{
                        valueType: 'DateTime',
                        labelFormat: dateFormat,
                        intervalType: interval,
                        interval: 1,
                        edgeLabelPlacement: 'Shift',
                        majorGridLines: { width: 0 },
                        labelIntersectAction: "Rotate45",
                    }}
                    primaryYAxis={{
                        labelFormat: '{value}',
                        minimum: 0,
                        lineStyle: { width: 0 },
                        majorTickLines: { width: 0 },
                        minorTickLines: { width: 0 }
                    }}
                    chartArea={{ border: { width: 0 } }}
                    tooltip={{ enable: true }}
                    palettes={getPalette()}
                    legendSettings={{ visible: true, position: 'Left', toggleVisibility: false }}
                    // zoomSettings={{
                    //     enableMouseWheelZooming: true,
                    //     enablePinchZooming: true,
                    //     // enableSelectionZooming: true
                    //  }}
                >
                    <Inject services={[StackingColumnSeries, DateTime, Legend, Tooltip, DataLabel, Category, Zoom, Export]} />
                    <SeriesCollectionDirective>
                        {/* first group is processed */}
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='processed'
                            name='Processed'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='a'
                        >
                        </SeriesDirective>
                        {/* second group is sent and unsent (because of prior bounce, prior unsub) */}
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='sent'
                            name='Sent'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='b'
                        >
                        </SeriesDirective>
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='priorbounced'
                            name='Prior Bounce'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='b'
                        >
                        </SeriesDirective>
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='priorunsubscribed'
                            name='Prior Unsubscribe'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='b'
                        >
                        </SeriesDirective>
                        {/* third group is delivered, blocked, bounce, deferred, dropped */}
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='delivered'
                            name='Delivered'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='c'
                        >
                        </SeriesDirective>
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='blocked'
                            name='Blocked'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='c'
                        >
                        </SeriesDirective>
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='bounce'
                            name='Bounce'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='c'
                        >
                        </SeriesDirective>
                        {/*<SeriesDirective*/}
                        {/*    dataSource={data}*/}
                        {/*    xName='date'*/}
                        {/*    yName='deferred'*/}
                        {/*    name='Deferred'*/}
                        {/*    // marker={{ visible: true, width: 10, height: 10 }}*/}
                        {/*    type='StackingColumn'*/}
                        {/*    stackingGroup='c'*/}
                        {/*>*/}
                        {/*</SeriesDirective>*/}
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='dropped'
                            name='Dropped'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='c'
                        >
                        </SeriesDirective>
                        {/* fourth group is open, click, spamreport, unsubscribe */}
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='open'
                            name='Open'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='d'
                        >
                        </SeriesDirective>
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='click'
                            name='Click'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='d'
                        >
                        </SeriesDirective>
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='spamreport'
                            name='Spam Report'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='d'
                        >
                        </SeriesDirective>
                        <SeriesDirective
                            dataSource={data}
                            xName='date'
                            yName='unsubscribe'
                            name='Unsubscribe'
                            // marker={{ visible: true, width: 10, height: 10 }}
                            type='StackingColumn'
                            stackingGroup='d'
                        >
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </>
        )
    }
}

export default DashChartColumns;