import React from "react";
import { Link } from "react-router-dom";

interface cardProps {
	link: string;
	color: string;
	icon: string;
	count: number;
	content: string;
	expected: number | false;
	iconSize?: number;
	text?: string;
	textAfter?: boolean;
}

const Card = (props: cardProps) => {
	const cardContent = (
		<div className="list bg-white shadow-sm rounded">
			<div className="list-item">
				<div style={{ backgroundColor: props.color }} className={`list-thumb avatar text-white rounded avatar${props.iconSize ?? "40"} shadow-sm`}>
					<i className={props.icon} />
				</div>
				<div className="list-body text-right">
					<span className="list-title fs-1x">
						{props.textAfter ? (
							<>
								{props.count.toLocaleString()} {props.text ? props.text : ""}
							</>
						) : (
							<>
								{props.text ? props.text : ""} {props.count.toLocaleString()}
							</>
						)}
					</span>
					<span className="list-content">{props.content}</span>
					{props.expected &&
						((props.count / props.expected) * 100 > 100 ? (
							<div className="text-muted fs12 pt-1">
								<i className="mr-2 fa fa-arrow-up text-success" /> {((props.count / props.expected) * 100).toFixed(2)}%
							</div>
						) : (
							<div className="text-muted fs12 pt-1">
								<i className="mr-2 fa fa-arrow-down text-danger" /> {((props.count / props.expected) * 100).toFixed(2)}%
							</div>
						))}
				</div>
			</div>
		</div>
	);

	if (props.link) {
		return (
			<div className="col mb-30">
				<Link to={props.link}>{cardContent}</Link>
			</div>
		);
	}

	return <div className="col mb-30">{cardContent}</div>;
};

export default Card;
