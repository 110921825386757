import {LogLevel} from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

//AD tenant ID config
export const msalConfig = {
    auth: {
        clientId: "5ee98f62-d458-4997-a277-3d5432054437",
        authority: "https://login.microsoftonline.com/d8a15cf7-32a2-49ab-890e-b12bb322405b",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['openid', 'profile', 'User.Read']
};

// REVIEW: These different scopes are required to grab the initial access token to use for the various API's.
//         'msScope' (graph scope is to obtain user's profile + info)  ATM will be the only one used/needed.

export const notificationScope = {
    scopes: ['https://crmlsnotification.azurewebsites.net/.default']
};

export const maceScope = {
    scopes: ['https://macewindu.azurewebsites.net/.default']
};

export const localScope = {
    scopes: ['26cf3f5c-cde4-4e31-bf07-2c1349ba5724/api-access']
}

export const msScope = {
    scopes: ['https://graph.microsoft.com/User.ReadBasic.All']
}

export const membershipScope = {
    scopes: ['https://membershipdirectory.azurewebsites.net/.default']
};

export const workflowScope = {
	scopes: ["api://workflow/.default"],
};

export const powerBiScope = {
    scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
