import React, {useState, useEffect} from "react";
import Card from "./Card";

const DashRollupStats = ({dateRange, campaignId, helper, completed, expected_count}) => {
    const [delivered, setDelivered] = useState(0);
    const [open, setOpen] = useState(0);
    const [bounce, setBounce] = useState(0);
    const [unsubscribe, setUnsubscribe] = useState(0);
    const [clicks, setClicks] = useState(0);

    const parseResults = (results) => {
        let data = results.webhookEventStats;
        data.forEach((point) => {
            let c = point.count;

            if (['delivered', 'processed', 'dropped'].includes(point.event)) {
                // use the unique context events
                c = point.uniqueContextEvents;
            } else if (['click','open'].includes(point.event)) {
                // if this is opens and clicks, use the unique members count and not the total
                c = point.uniqueMembers;
            }

            switch (point.event) {
                case 'delivered':
                    setDelivered(c)
                    break;
                case 'open':
                    setOpen(c)
                    break;
                case 'bounce':
                    setBounce(c)
                    break;
                case 'unsubscribe':
                    setUnsubscribe(c)
                    break;
                case 'click':
                    setClicks(c)
                    break;
                default:
                    break;
            }
        });
    }

    useEffect(() => {
        let criteria = {...dateRange}
        if (campaignId && campaignId.length) {
            criteria.emailContextId = campaignId;
        }

        if (helper) {
            console.log("fetching rollup");
            helper.setNotification();
            helper.fetchReportData(criteria)
                .then(
                    (result) => {
                        console.log(result);
                        if (result.isSuccessful) {
                            parseResults(result.results[0]);
                        }
                    }
                )
        }
    }, [dateRange, JSON.stringify(helper)])

    const generateLink = (event) => {
        if (completed) {
            return false;
        } else if (campaignId) {
            return "/communications/reports?event=" + event + "&campaign_id=" + campaignId + "&start=" + dateRange.startDate;
        } else {
            return "/communications/reports?event=" + event + "&start=" + dateRange.startDate;
        }
    }

    return (
        <>
            <Card
                link={generateLink('delivered')}
                color="#94df4a"
                icon='fa fa-paper-plane'
                count={delivered}
                content='Delivered'
                expected={expected_count}
            />
            <Card
                link={generateLink('open')}
                color="#86d4f5"
                icon='fa fa-envelope-open'
                count={open}
                content='Unique Opens'
                expected={expected_count}
            />
            {completed &&
                <Card
                    link={generateLink('click')}
                    color="#69c1e6"
                    icon='fas fa-mouse-pointer'
                    count={clicks}
                    content='Unique Clicks'
                    expected={expected_count}
                />
            }
            <Card
                link={generateLink('bounce')}
                color="#f3d768"
                icon='fa fa-level-up-alt'
                count={bounce}
                content='Bounces'
                expected={expected_count}
            />
            <Card
                link={generateLink('unsubscribe')}
                color="#fa625e"
                icon='fa fa-times-circle'
                count={unsubscribe}
                content='Unsubscribes'
                expected={expected_count}
            />
        </>
    );
};

export default DashRollupStats;