import React, {useState} from 'react';

/**** USAGE EXAMPLE 
 * 
 *      const testAction = () => {
 *          console.log('TRIGGERED TEST ACTION')
 *      }
 * 
 *      const itemActions = [
 *          { label: 'Custom Action', iconClass: 'fas fa-check text-info', callback: testAction },
 *          { label: 'Another Action', iconClass: 'fas fa-check-circle text-success', callback: anotherAction }
 *      ]
 * 
 *      <GenericActionsMenu actions={itemActions}/>
 * 
 * ******/


function GenericActionsMenu({actions}: {actions: any[] | undefined}) {
    const [isActive, setIsActive] = useState(false);
    let className = 'crmls-actions-dropdown-container';
    className += (isActive) ? ' active' : '';

    const toggleActiveState = () => {
        if (isActive) {
            closeMenu();
        } else {
            setIsActive(!isActive);
            document.addEventListener('click', closeMenu);
        }
    }

    const closeMenu = () => {
        setIsActive(false);
        document.removeEventListener('click', closeMenu);
    }

    if (actions && actions.length) {
        return (
            <div className={className} onClick={toggleActiveState}>
                <div className="crmls-actions-menu"></div>
                {isActive &&
                    <div className="crmls-actions-dropdown">
                        {actions.map((action, i) => {
                            return (<div key={i} onClick={(action.callback) ? action.callback : () => {}}>{(action?.iconClass) ? <i className={action.iconClass} /> : ''}{action.label}</div>)
                        })}
                    </div>
                }   
            </div>
        );
    }
    return <></>;
}

export default GenericActionsMenu;
