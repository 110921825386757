import React from "react";
import { useAppSelector } from "../../app/hooks";
import { getUser } from "../../features/userFeature/userSlice";
import { useMsal } from "@azure/msal-react";

function User() {
	const user = useAppSelector(getUser);
	const { instance } = useMsal();

	return (
		<>
			<a className="nav-link" href="#" id="dropdown-user" role="button" data-toggle="dropdown">
				<span className="user-states states-online">
					<img src={user?.image} width="35" alt="" className="img-fluid rounded-circle" />
				</span>
				<span className="ml-2 h-lg-down dropdown-toggle">Hi, {user?.first}</span>
			</a>
			<div className="dropdown-menu dropdown-menu-right">
				<div className="text-center p-3 pt-0 b-b mb-5">
					<span className="mb-0 d-block font300 text-title fs-1x">
						Hi!{" "}
						<span className="font700">
							{user?.first} {user?.last}
						</span>
					</span>
					<span className="fs12 mb-0 text-muted">{user?.title}</span>
				</div>
				{/*<a className="dropdown-item" href="#"><i className="icon-User"></i>My Profile</a>*/}
				<a
					className="dropdown-item"
					onClick={() => {
						instance.logout();
					}}
				>
					<i className="icon-Power"></i> logout
				</a>
			</div>
		</>
	);
}

export default User;
