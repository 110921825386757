import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ThemeLoader from "../Components/Widgets/ThemeLoader";

// lazy-load home components
const CommunicationsHome = lazy(() => import("./Main/CommunicationsHome"));
const ComplianceHome = lazy(() => import("./Main/ComplianceHome"));
const CustomerCareHome = lazy(() => import("./Main/CustomerCareHome"));
const MembershipHome = lazy(() => import("./Main/MembershipHome"));
const DevelopmentHome = lazy(() => import("./Main/DevelopmentHome"));
const FinanceHome = lazy(() => import("./Main/FinanceHome"));
const OperationsHome = lazy(() => import("./Main/OperationsHome"));
const TrainingHome = lazy(() => import("./Main/TrainingHome"));
const ReportingHome = lazy(() => import("./Main/ReportingHome"));
const NoMatch = lazy(() => import("./NoMatch"));

const Main = () => {
    return (
        <Suspense fallback={<ThemeLoader />}>
            <Switch>
                <Route path="/communications">
                    <CommunicationsHome />
                </Route>
                <Route path="/compliance">
                    <ComplianceHome />
                </Route>
                <Route path="/support">
                    <CustomerCareHome />
                </Route>
                <Route path="/membership">
                    <MembershipHome />
                </Route>
                <Route path="/dev">
                    <DevelopmentHome />
                </Route>
                <Route path="/finance">
                    <FinanceHome />
                </Route>
                <Route path="/operations">
                    <OperationsHome />
                </Route>
                <Route path="/training">
                    <TrainingHome />
                </Route>
                <Route exact={true} path="/">
                    <Redirect to="/communications" />
                </Route>
                <Route path="/reporting">
                    <ReportingHome/>
                </Route>
                <Route component={NoMatch} />
            </Switch>
        </Suspense>
    );
}

export default Main;
