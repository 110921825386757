import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "../features/userFeature/userSlice";
import prefsReducer from "../features/prefsFeature/prefsSlice";
import complianceReducer from "../features/complianceFeature/complianceSlice";

export const store = configureStore({
	reducer: {
		user: userReducer,
		prefs: prefsReducer,
		compliance: complianceReducer
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
        ReturnType,
        RootState,
        unknown,
        Action<string>
    >;
