import React, {useState} from "react";
import Houses from '../../Assets/houses.jpg';
import {useLocation} from "react-router-dom";
import Swal from 'sweetalert2'
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.css';
import axios from "axios";

const Unsubscribe = () => {
    const development = (window.location.href.includes('localhost') || process.env.NODE_ENV === 'development' || window.location.href.includes('ui-dev'));
    const query = new URLSearchParams(useLocation().search);

    const [email, setEmail] = useState(query.get('email') ?? '');
    const campaignId = query.get('campaignId') ?? '';
    const encryptedId = query.get('encryptedId') ?? '';

    const base_config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }

    const doUnsubscribe = () => {
        const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let criteria = {
            email: email,
            emailContextId: campaignId,
            encryptedId: decodeURIComponent(encryptedId.replace(/ /g, '+'))
            // encryptedId: encryptedId
        };

        if (email_regex.test(email)) {
            let base_url = '';
            if (development) {
                // force to the staging endpoints
                base_url = "https://crmlsnotification-dev.azurewebsites.net/api/app/";
            } else {
                base_url = "https://crmlsnotification.azurewebsites.net/api/app/";
            }

            axios.post(base_url + 'OptOutRequest/Unsubscribe', criteria, base_config)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.isSuccessful) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Unsubscribed',
                            html: 'You have been unsubscribed from this communication.',
                        })
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Unexpected error',
                        html: error.response.data.message,
                    })
                });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Invalid email',
                html: 'Please supply a valid email address',
            })
        }
    }

    return (
            <main className="content bg-white">
                <div className="container-fluid flex d-flex">
                    <div className='row flex align-items-center'>
                        <div className='col-lg-6 d-flex flex h-lg-down full-height bg-pattern bg-fHeight' style={{backgroundImage: `url(${Houses})`}} />
                        <div className='col-lg-4 col-md-5 align-items-center col-sm-6 ml-auto flex d-flex mr-auto full-height pt-40 pb-20'>
                            <div className="w100 d-block text-center">
                                <span className="avatar avatar60 ml-auto mr-auto bg-gradient-primary text-white rounded-circle">
                                    <i className="icon-Paper-Plane" />
                                </span>
                                <div className="clearfix pt-3">
                                    <h5 className="h3 font400">Sorry to see you go!</h5>
                                    <p className="text-muted pt-2">Unsubscribe from further marketing emails</p>
                                    <form>
                                        <div className="row no-margin pt-3 pb-3">
                                            <div className="col-8 pr-2 pl-0">
                                                <input type="email" defaultValue={email} onChange={(e) => setEmail(e.target.value)} required className="form-control" placeholder="Enter Email" />
                                            </div>
                                            <div className="col-4 no-padding text-center">
                                                <button onClick={doUnsubscribe} type="button" className="btn btn-primary btn-block"><i className="far fa-paper-plane mr-2" />Bye!</button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="title-sep sep-white">
                                        <span>Or Follow Us</span>
                                    </div>
                                    <div className="clearfix pt-3">
                                        <a href="https://www.facebook.com/crmls" target="_blank" className="btn-icon-o si-colored-facebook mb-1 mr-1" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
                                        <a href="https://twitter.com/crmlsnews" target="_blank" className="btn-icon-o si-colored-twitter mb-1 mr-1" rel="noopener noreferrer"><i className="fab fa-twitter" /></a>
                                        <a href="https://www.linkedin.com/company/crmls" target="_blank" className="btn-icon-o si-colored-linkedin mb-1 mr-1" rel="noopener noreferrer"><i className="fab fa-linkedin" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <small className="text-muted">
                            <span>Photo by <a href="https://unsplash.com/@chuttersnap?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank">CHUTTERSNAP</a> on <a href="https://unsplash.com/s/photos/real-estate?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank">Unsplash</a></span>
                        </small>
                    </div>
                </div>
            </main>
    )
}

export default Unsubscribe;
