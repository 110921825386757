/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { selectorDefinition, selectorQueryDefinition, SyncfusionOptions } from "../../Reporting/Configuration";
import GenericTrainingSelect from "../../../Widgets/GenericTrainingSelect";
import { enumSelectOptionType } from "../../../Utilities/formHelper";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { selectPrefByPath, setAPIPref } from "../../../../features/prefsFeature/prefsSlice";
import { useAppDispatch } from "../../../../app/hooks";

type selectorValue = {
	id: string;
	value: string | boolean | number; // TODO: possibly add number support?
};

const GenericSyncfusionSelectors = ({ options, setSelectorQuery, showClearBtn = false }: { options: SyncfusionOptions; setSelectorQuery: (selectorDefinitions: selectorQueryDefinition[]) => void; showClearBtn: boolean }) => {
	const selectors = options.selectors;
	const [selectorValues, setSelectorValues] = useState<selectorValue[]>([]);
	const [activeFilters, setActiveFilters] = useState(false);
	const [filterInputs, setFilterInputs] = useState<any[]>([]);
	const [checkedDefaults, setCheckedDefaults] = useState(false);
	const filterClass = "form-control list-filter";
	const dispatch = useAppDispatch();
	let prefsPath = [options.resourceType, options.resourceName, "selectors"].join(".");

	// Set query data as filters change
	useEffect(() => {
		if (!checkedDefaults) {
			checkDefaultValues();
		}
		let svs: selectorQueryDefinition[] = [];
		if (selectorValues.length) {
			selectorValues.forEach((sv) => {
				svs.push({
					field: sv.id,
					op: 0,
					values: [sv.value],
				});
			});
			setActiveFilters(true);
		} else {
			setActiveFilters(false);
		}
		setSelectorQuery(svs);
		dispatch(
			setAPIPref({
				key: prefsPath,
				value: svs,
			})
		);
	}, [dispatch, setSelectorQuery, selectorValues]);

	// Set selected filter values for new or updated filters
	const selectorChangeHandler = (id: string, value: string | boolean | number) => {
		let s = selectorValues.find((selector) => selector.id === id);
		if (s) {
			s.value = value;
		} else {
			selectorValues.push({
				id: id,
				value: value,
			});
		}
		// remove any values that are unset
		let newSelectedValues = selectorValues.filter((s) => s.value !== "Select One");
		setSelectorValues(newSelectedValues);
	};

	// Build filter html elements
	const buildSelector = (selector: selectorDefinition) => {
		// for now we can only support items where we know the values (eg. enum) or have a foreign key relationship)
		let supportedDataTypes = ["select", "staticSelect", "staticSelectInt", "enumSelect", "boolean"];
		// this is not a good way to do it. . .
		let selectorColumn = options.columns.filter((column) => supportedDataTypes.includes(column.dataType)).find((column) => column.id === selector.id);
		if (selectorColumn && selectorColumn.id && supportedDataTypes.includes(selectorColumn.dataType)) {
			let i = selectorColumn.id;
			let currentValue = selectorValues.find((filter) => filter.id === i);
			switch (selectorColumn.dataType) {
				case "select":
					return (
						<GenericTrainingSelect
							key={i}
							id={i}
							resource_name={selectorColumn.selectResource}
							resource_label={selector?.label || ""}
							onChange={(e: any) => selectorChangeHandler(i, e.target.value)}
							onBlur={null}
							service_name={selector.service}
							value={currentValue && typeof currentValue.value === "string" ? currentValue.value : "Select One"}
							cssClass={`${selector?.cssClass} ${filterClass}`}
							select_field={selector.selectField ?? undefined}
							valueKey={selector.valueKey ?? undefined}
						/>
					);
				case "staticSelect":
					return (
						<select key={i} id={i} onChange={(e: any) => selectorChangeHandler(i, e.target.value)} value={currentValue && typeof currentValue.value === "string" ? currentValue.value : "Select One"} className={`${selector?.cssClass} ${filterClass}`}>
							<option value="Select One">Select {selector.label ? selector.label : "One"}</option>
							{selectorColumn.values &&
								selectorColumn.values.map((v: string) => {
									return (
										<option key={v} value={v}>
											{v}
										</option>
									);
								})}
						</select>
					);
				case "staticSelectInt":
					return (
						<select key={i} id={i} onChange={(e: any) => selectorChangeHandler(i, e.target.value)} value={currentValue && typeof currentValue.value === "string" ? currentValue.value : "Select One"} className={`${selector?.cssClass} ${filterClass}`}>
							<option value="Select One">Select {selector.label ? selector.label : "One"}</option>
							{selectorColumn.values &&
								selectorColumn.values.map((v: string, i: number) => {
									// add one to the value, since we're not using 0 indexes in the values of the DB
									return (
										<option key={i} value={i + 1}>
											{v}
										</option>
									);
								})}
						</select>
					);
				case "enumSelect":
					if (selectorColumn.values[0]?.category) {
						return (
							<select key={i} id={i} onChange={(e: any) => selectorChangeHandler(i, e.target.value)} value={currentValue && typeof currentValue.value === "string" ? currentValue.value : "Select One"} className={`${selector?.cssClass} ${filterClass}`}>
								<option value="Select One">Select {selector.label ? selector.label : "One"}</option>
								{Object.values(
									selectorColumn.values.reduce((acc: any, obj: any) => {
										const key = obj?.category;

										if (!acc[key]) {
											acc[key] = {
												groupTitle: key,
												groups: [],
											};
										}

										acc[key].groups.push(obj);

										return acc;
									}, {})
								).map((item: any, idx: number) => {
									return (
										<optgroup key={idx} label={item.groupTitle}>
											{item.groups.map((group: any) => (
												<option key={group.value} value={group.value}>
													{group.label}
												</option>
											))}
										</optgroup>
									);
								})}
							</select>
						);
					} else {
						return (
							<select key={i} id={i} onChange={(e: any) => selectorChangeHandler(i, e.target.value)} value={currentValue && typeof currentValue.value === "string" ? currentValue.value : "Select One"} className={`${selector?.cssClass} ${filterClass}`}>
								<option value="Select One">Select {selector.label ? selector.label : "One"}</option>
								{selectorColumn.values &&
									selectorColumn.values.map((v: enumSelectOptionType) => {
										return (
											<option style={v.hidden ? { display: "none" } : undefined} key={v.value} value={v.value}>
												{v.label}
											</option>
										);
									})}
							</select>
						);
					}
				case "boolean":
					return (
						<div>
							<label htmlFor="sd" style={{ padding: "0px 5px 0px 0" }}>
								{" "}
								{selectorColumn.label}{" "}
							</label>
							<SwitchComponent id="sd" checked={!!selector.default} change={(e) => selectorChangeHandler(i, e.checked)} />
						</div>
					);
			}
		} else if (selectorColumn && !supportedDataTypes.includes(selectorColumn.dataType)) {
			console.log("Datatype not supported for selector", selector);
		} else {
			console.log("No column definition found for selector", selector);
		}

		// fail through to
		return <></>;
	};

	const refreshFilters = () => {
		if (selectors && selectors.length) {
			setSelectorValues([]);
		}
	};

	const buildFilters = () => {
		if (selectors && selectors.length) {
			setFilterInputs(selectors.map((selector) => buildSelector(selector)));
		}
	};

	useEffect(() => {
		buildFilters();
	}, [selectorValues]);

	const checkDefaultValues = () => {
		if (selectors && selectors.length && options.enablePersistence !== false) {
			const lsFilters = selectPrefByPath(prefsPath);
			if (lsFilters && lsFilters.length) {
				// We have values in redux prefs - populate dropdowns with those values
				lsFilters.map((filter: any) => {
					return selectorChangeHandler(filter.field, filter.values[0]);
				});
			} else {
				// No stored prefs, so check if we have default values & populate default filters
				let filtersWithDefaultValue = selectors.filter((select) => select.default || select.dynamicDefault);
				filtersWithDefaultValue.map((filter) => {
					// check for dynamic default first
					if (filter.dynamicDefault) {
						let filterValue = filter.dynamicDefault();
						if (filterValue) {
							return selectorChangeHandler(filter.id, filterValue);
						}
					} else if (filter.default) {
						return selectorChangeHandler(filter.id, filter.default);
					}
					return true;
				});
			}
		}
		setCheckedDefaults(true);
	};

	if (!selectors || selectors.length === 0) {
		return <></>;
	} else {
		return (
			<div className="d-flex flex-row justify-content-end p-3 pb-0">
				{showClearBtn && activeFilters && (
					<button type="button" className="btn btn-sm btn-icon btn-light" onClick={refreshFilters}>
						<i className="fas fa-undo"></i> Reset Filters
					</button>
				)}
				{filterInputs}
			</div>
		);
	}
};

export default GenericSyncfusionSelectors;
