import React, { useEffect, useRef, useState } from "react";
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend, PieSeries, AccumulationDataLabel, Inject } from "@syncfusion/ej2-react-charts";
import { getPalette } from "../Utilities";
import dayjs from "dayjs";
import ThemeLoader from "./ThemeLoader";

const ReportDonutChart = ({ dateRange, campaignType, campaignId, helper, showTextStats = false, aorShortname }) => {
	const criteria = useRef();
	const [items, setItems] = useState({});
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isEmpty, setIsEmpty] = useState(false);
	const palette = getPalette();

	useEffect(() => {
		if (helper === null) {
			return;
		}

		let c = {
			startDate: dayjs(dateRange.startDate).startOf("day").toISOString(),
			endDate: dayjs(dateRange.endDate).endOf("day").toISOString(),
		};

		if (campaignId && campaignId.length) {
			c.emailContextId = campaignId;
		}

		if (aorShortname && aorShortname.length) {
			c.audienceCriteria = [
				{
					field: "AorShortName",
					op: "Equal",
					values: [aorShortname],
				},
			];
		}

		if (campaignType && campaignType.length) {
			c.emailCategory = campaignType;
		}

		if (criteria.current !== c || criteria.current === undefined) {
			fetchData(c);
			criteria.current = c;
		}
	}, [dateRange, campaignType, campaignId, aorShortname, JSON.stringify(helper)]);

	const parseResults = (results) => {
		let events_we_track = ["sent", "delivered", "priorbounced", "priorunsubscribed"];
		let data = results.webhookEventStats;
		let d_data = [];

		data.forEach((point) => {
			let c = point.count;
			let label = "";

			if (events_we_track.includes(point.event)) {
				switch (point.event) {
					case "sent":
					default:
						label = "Sent";
						break;
					case "delivered":
						label = "Delivered";
						c = point.uniqueContextEvents;
						break;
					case "priorbounced":
						label = "Prior Bounce";
						break;
					case "priorunsubscribed":
						label = "Prior Unsubscribe";
						break;
				}

				let shape = {
					x: label,
					y: c,
				};
				d_data.push(shape);
			}
		});

		if (d_data.length === 0) {
			setIsEmpty(true);
		} else {
			setIsEmpty(false);
		}

		return d_data;
	};

	const fetchData = (criteria) => {
		setIsLoaded(false);
		setError(null);
		if (helper) {
			helper.setNotification();
			helper.fetchReportData(criteria).then(
				(result) => {
					if (result.isSuccessful) {
						setError(null);
						setIsLoaded(true);
						setItems(parseResults(result.results[0]));
					} else {
						setError(result.message);
					}
				},
				(error) => {
					setIsLoaded(true);
					setError(error.message);
				}
			);
		}
	};

	if (error) {
		return <div>Error: {error}</div>;
	} else if (!isLoaded) {
		return <ThemeLoader />;
	} else {
		if (isEmpty) {
			return <p className="text-danger">No results found</p>;
		} else {
			return (
				<>
					<AccumulationChartComponent
						id="pie-chart"
						// title='Event Aggregates'
						legendSettings={{
							visible: true,
							position: "Top",
						}}
						enableSmartLabels={true}
						tooltip={{ enable: true }}
						pointRender={(args) => {
							if (args.point.x === "Sent") {
								args.fill = palette[1];
							} else if (args.point.x === "Delivered") {
								args.fill = palette[4];
							} else if (args.point.x === "Prior Bounce") {
								args.fill = palette[2];
							} else if (args.point.x === "Prior Unsubscribe") {
								args.fill = palette[3];
							}
						}}
					>
						<Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel]} />
						<AccumulationSeriesCollectionDirective>
							<AccumulationSeriesDirective
								name="Events"
								dataSource={items}
								xName="x"
								yName="y"
								innerRadius="40%"
								startAngle={0}
								endAngle={360}
								radius="70%"
								explode={true}
								explodeOffset="15%"
								explodeIndex={3}
								explodeRadius={0.2}
								dataLabel={{
									visible: true,
									name: "text",
									position: "Inside",
									font: {
										fontWeight: "600",
										color: "#ffffff",
									},
								}}
							></AccumulationSeriesDirective>
						</AccumulationSeriesCollectionDirective>
					</AccumulationChartComponent>
					{items.length && showTextStats && (
						<>
							<h6>Details:</h6>
							<ul className="list-key-value">
								{items.map((item, index) => {
									return (
										<li key={index}>
											<span className="list-key">{item.x}:</span> <span className="list-value">{item.y}</span>
										</li>
									);
								})}
							</ul>
						</>
					)}
				</>
			);
		}
	}
};

export default ReportDonutChart;
