import React from 'react';
import Loader from 'react-loader-spinner';

// @todo: vk - replace instances of Loader with ThemeLoader
// set default props to use theme variables
const defaultParams = {
    type: 'ThreeDots',
    color: '#2BAD60',
    height: 100,
    width: 100,
    customClass: 'default-loader'
}

const ThemeLoader = (props) => (
    <div className={`container-fluid theme-loader ${props.customClass || defaultParams.customClass}`}>
        <Loader type={props.type || defaultParams.type} color={props.color || defaultParams.color} height={props.height || defaultParams.height} width={props.width || defaultParams.width} />
    </div>
)

export default ThemeLoader;
