import React from "react";
import Houses from '../Assets/houses.jpg';
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.css';
import {Line} from "react-chartjs-2";
import { Link } from "react-router-dom";

const Unauthenticated = () => {
    return (
        <main className="content bg-white">
            <div className="container-fluid flex d-flex">
                <div className='row flex align-items-center'>
                    <div className='col-lg-6 d-flex flex h-lg-down full-height bg-pattern bg-fHeight' style={{backgroundImage: `url(${Houses})`}} />
                    <div className='col-lg-4 col-md-5 align-items-center col-sm-6 ml-auto flex d-flex mr-auto full-height pt-40 pb-20'>
                        <div className="w100 d-block text-center">
                                <span className="avatar avatar60 ml-auto mr-auto bg-gradient-primary text-white rounded-circle">
                                    <i className="icon-Paper-Plane" />
                                </span>
                            <div className="clearfix pt-3">
                                <h5 className="h3 font400">CRMLS Internal Resource</h5>
                                <p className="text-muted pt-2">COSMOS is an internal application. If you came across this page in error please notify IT.</p>

                                <div className="title-sep sep-white">
                                    <span>Or Follow Us</span>
                                </div>
                                <div className="clearfix pt-3">
                                    <a href="https://www.facebook.com/crmls" target="_blank" className="btn-icon-o si-colored-facebook mb-1 mr-1" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
                                    <a href="https://twitter.com/crmlsnews" target="_blank" className="btn-icon-o si-colored-twitter mb-1 mr-1" rel="noopener noreferrer"><i className="fab fa-twitter" /></a>
                                    <a href="https://www.linkedin.com/company/crmls" target="_blank" className="btn-icon-o si-colored-linkedin mb-1 mr-1" rel="noopener noreferrer"><i className="fab fa-linkedin" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <small className="text-muted">
                        <span>Photo by <a href="https://unsplash.com/@chuttersnap?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">CHUTTERSNAP</a> on <a href="https://unsplash.com/s/photos/real-estate?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank">Unsplash</a></span>
                    </small>
                </div>
            </div>
        </main>
    )
}

export default Unauthenticated;
