import React from "react";
import {CampaignResource} from "./Campaigns";
import {selectorQueryDefinition} from "../../Reporting/Configuration";
import GenericSyncfusionTable from "../../SubComponents/GenericSyncfusion/GenericSyncfusionTable";

const CampaignWrapper = ({title, query, pageSize}: {title: string, query: selectorQueryDefinition[], pageSize: number}) => {
    let configuration = {...CampaignResource};
    configuration.title = title;
    configuration.showNew = false;
    configuration.defaultQuery = query;
    configuration.defaultPageSize = pageSize;
    configuration.selectors = [];

    return (
        <div className="container-fluid">
            <GenericSyncfusionTable options={configuration} />
        </div>
    );
}

export default CampaignWrapper;
